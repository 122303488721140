@import '@kwara/components/src/foundations/_mixins.scss';

.fullText {
  display: none;
  @include respond-to(viewport-7) {
    display: inline;
  }
}

.shortText {
  display: inline;
  @include respond-to(viewport-7) {
    display: none;
  }
}
