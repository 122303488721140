@import '@kwara/components/src/foundations/_mixins.scss';

.regular {
  width: 90vw;
  @include respond-to(viewport-7) {
    width: 518px;
  }
}

.medium {
  width: 100%;
  max-width: 700px;
}

.wide {
  width: 90vw;

  @include respond-to(viewport-9) {
    width: 816px;
  }
}

.widest {
  width: 90vw;

  @include respond-to(viewport-7) {
    width: 1104px;
  }
}

.xx-wide {
  width: 90vw;

  @include respond-to(viewport-7) {
    width: 1217px;
  }
}

.stretch {
  width: 100%;
}
