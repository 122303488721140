@import '@kwara/components/src/foundations/_mixins.scss';

.ButtonRight {
  margin-left: 0.2rem;
  @include respond-to(viewport-3) {
    margin-left: 1rem;
  }
}

.ButtonLeft {
  margin-right: 0.2rem;
  @include respond-to(viewport-3) {
    margin-right: 1rem;
  }
}
