@import '@kwara/components/src/foundations/_mixins.scss';
@import '@kwara/components/src/foundations/_variables.scss';

.Section {
  padding: 24px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'a c'
    'b b';
  @include respond-to(viewport-7) {
    grid-template-areas: 'a b c';
    grid-template-columns: 1fr 2fr 1fr;
  }
  border: none;
  margin-top: 24px;
  padding: 12px;
}

.Content {
  grid-area: b;
  text-align: left;
  background-color: mobile-brand-col('mobile-primary-50');
  @include respond-to(viewport-7) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Section:last-child {
  border-bottom: none;
}

.Heading {
  grid-area: a;
  min-width: 144px;
  text-align: left;
}

.EditColumn {
  grid-area: c;
  text-align: right;
}

.wrap {
  display: flex;
  flex-direction: column;
  @include respond-to(viewport-7) {
    flex-direction: row;
  }
}
