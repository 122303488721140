.banner {
  margin-bottom: 2rem;
}

.banner__title {
  margin: 0;
  padding: 0;
  margin-bottom: 7px;
}

.banner__message {
  margin: 0;
  padding: 0;
}
