@import '@kwara/components/src/foundations/_mixins.scss';
@import '@kwara/components/src/foundations/_variables.scss';

.Container {
  width: 90%;
}

.good .Inner {
  background: linear-gradient(111.18deg, #473be7 19.52%, #397f87 83.32%);
  color: brand-col(white);
}

.good .Btn {
  background: brand-col(white);
  color: brand-col(primary-500);

  &:hover {
    background: brand-col(white);
    color: brand-col(primary-500);
  }
}

.neutral .Inner {
  background-color: brand-col(white);
  background-image: url('./neutral.png');
  border-color: #eee;
  border-bottom: 6px solid mobile-brand-col(mobile-primary-500);
  color: brand-col(primary-500);
  height: 327px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bad .Inner {
  background-color: brand-col(red-100);
}

.info .Inner {
  background-color: brand-col(info-100);
}

.Inner {
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  border: 1px solid transparent;
  border-bottom: 6px solid mobile-brand-col(mobile-primary-500);
  text-align: center;
  overflow: visible;
  padding: 1rem;
}

.Inner:before {
  content: ' ';
  display: block;
  background-repeat: no-repeat;
  background-size: 298px 240px;
  width: 298px;
  height: 240px;
  transform: translateY(-21px);
}

.Footer {
  width: 100%;
}

.RowSize {
  width: 100%;
}

.neutral .Inner:before {
  height: 50px;
}

.good .Inner:before {
  background-image: url('./good.png');
}

.bad .Inner:before {
  background-image: url('./bad.png');
}

.info .Inner:before {
  background-image: url('./bad.png');
}
