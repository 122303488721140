@import '@kwara/components/src/foundations/_variables.scss';

.Select {
  /* Hide default arrow */
  -moz-appearance: none;
  -webkit-appearance: none;

  /* background-image set as data URI in Component */
  background-repeat: no-repeat;

  border: none;
  background-color: brand-col('indigo-50');
}

.Select:disabled {
  opacity: 0.6;
  color: black;
  cursor: not-allowed;
}

.Select.isNotInline {
  background-position: right 1rem center;
}

.Select.isNotInline:focus {
  outline: none;
}

.Select.isNotInline.small {
  height: 48px;
  border-radius: 8px;
  padding: 0 22px;
  background-size: 8px 14px;
}

.Select.isInline {
  background-position: right center;
  border: none;
  padding-right: 1rem;
  width: auto;
  max-width: 10em;
  text-overflow: ellipsis;
}
