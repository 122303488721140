@use '@kwara/components/src/foundations/_variables.scss';

/*****************************
 * @styles for index.tsx
 *****************************/
.savings-container {
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 37.5rem;
  margin: 0 auto;
  padding-left: 3rem;

  & h2 {
    padding: 0;
    margin: 0;
  }

  & ul {
    list-style: none;
    margin: 0;
  }
}

/*****************************
 * @styles for History.tsx
 *****************************/
.history-container {
  width: 100%;
  max-width: 21.3125rem;
  margin-bottom: 1rem;
}

.history-container__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    border: 0;
    border: none;
    cursor: pointer;
    padding: 0.625rem;
  }
}

.tabs-wrapper {
  background-color: variables.brand-col(white);
  border: 0.125rem solid variables.brand-col(neutral-300);
  box-shadow: 0rem 0rem 1.5rem 0.3125rem variables.brand-col(box-shadow);
  border-radius: 0.375rem;
}

.six-m {
  background-color: transparent;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.one-yr {
  background-color: transparent;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  color: variables.brand-col(neutral-500);
}

.six-m--active,
.one-yr--active {
  background-color: variables.brand-col(neutral-500);
  color: variables.brand-col(white);
}

.sav-his {
  padding: 0;
  padding-top: 1rem;

  & li {
    padding: 0.4rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/*****************************
 * @styles for Statement.tsx
 *****************************/
.statement-container {
  width: 100%;
  max-width: 37.5rem;
}

.sav-st {
  display: flex;
  justify-content: space-between;
  padding: 0;
  padding-top: 1rem;

  & li div {
    &:nth-child(1) {
      color: variables.brand-col(neutral-500);
    }

    &:nth-child(2) {
      color: #1e293b;
    }
  }
}

/*****************************
 * @styles for Loader.tsx
 *****************************/
.loader {
  width: 100%;
  max-width: 21.3125rem;
}
