.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.container--100 {
  background-color: rgba(65, 65, 65, 1);
}

.container--90 {
  background-color: rgba(65, 65, 65, 0.9);
}

.container--80 {
  background-color: rgba(65, 65, 65, 0.8);
}

.container--70 {
  background-color: rgba(65, 65, 65, 0.7);
}

.container--20 {
  background-color: rgba(65, 65, 65, 0.2);
}
