@import '@kwara/components/src/foundations/_mixins.scss';
@import '@kwara/components/src/foundations/_variables.scss';

.Section {
  border: 1px solid brand-col('neutral-300');
  border-radius: 6px;
  margin-top: 24px;
  padding: 12px;
  display: grid;
  grid-template-areas:
    'a c'
    'b b';
}

.Heading {
  grid-area: a;
  min-width: 144px;
  text-align: left;
}

.EditColumn {
  grid-area: c;
  text-align: right;
}
