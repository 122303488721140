@import '@kwara/components/src/foundations/_variables.scss';

/***********************
 * @index.tsx styles
 ************************/
.allLoansWrapper {
  margin: 2.5rem 0;

  & h1 {
    color: brand-col('neutral-700');
    padding: 0;
    margin: 0;
  }
}

.loansListbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;

  & li {
    list-style: none;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: 14px;
  }

  & label {
    cursor: pointer;
  }
}

.activeLoan {
  border-color: brand-col('primary-500') !important;
  border-width: 2px
}

.amountListbox {
  display: flex;
  padding: 0;
  margin: 0;
}

.addNotes {
  margin: 2rem 0;
}

/***********************
 * @AmountList.tsx styles
 ************************/
.heading {
  color: brand-col('neutral-600');
  padding: 0;
  margin: 0;
}

.amountItem {
  padding: 0;
  margin: 0;
  padding-right: 1rem;
  list-style: none;

  & section {
    & p {
      color: brand-col('neutral-800');
      padding: 0;
      margin: 0;
    }
  }

  &:not(:last-child) {
    border-right: solid 1px brand-col('neutral-300');
  }

  &:not(:first-child) {
    border-right: solid 1px brand-col('neutral-300');
    padding-left: 1rem;
  }

  &:last-child {
    border: 0;
  }
}
