@import '@kwara/components/src/foundations/_variables.scss';

.Panel {
  max-width: 500px;
}

.background {
  background: transparentize(brand-col('primary-600'), 0.95);
}

.Breakdown {
  border-radius: 8px;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.04);
  width: 220px;

  tbody {
    tr td {
      padding-top: 0.25rem;
      padding-bottom: 0.5rem;
    }
  }

  tfoot {
    tr td {
      padding-top: 0.5rem;
      padding-bottom: 0.25rem;
    }
  }
}
