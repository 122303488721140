@import '@kwara/components/src/foundations/_mixins.scss';
@import '@kwara/components/src/foundations/_variables.scss';

.Container {
  width: 384px;
  padding-top: 21px;
}

.good .Inner {
  background-color: brand-col(teal-100);
}

.bad .Inner {
  background-color: brand-col(red-100);
}

.info .Inner {
  background-color: brand-col(info-100);
}

.Inner {
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  border: 1px solid transparent;
  text-align: center;
  overflow: visible;
}

.Inner:before {
  content: ' ';
  display: block;
  background-repeat: no-repeat;
  background-size: 298px 240px;
  width: 298px;
  height: 240px;
  transform: translateY(-21px);
}

.Footer {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.RowSize {
  width: inherit;
}

.good .Inner:before {
  background-image: url('./good.png');
}

.bad .Inner:before {
  background-image: url('./bad.png');
}

.info .Inner:before {
  background-image: url('./bad.png');
}
