@import '@kwara/components/src/foundations/_mixins.scss';

.OverflowScroll {
  overflow: scroll;
}

.SubStep {
  width: 100%;
  height: 100%;
  /* WizardActions height */
}

.SubStepBottomMargin {
  margin-bottom: 81px;
}

.WizardSubstep .Substep {
  padding-top: 72px;
}

.SubStep.actionsHidden {
  margin-bottom: 0;
}

.SubStepInner {
  margin: 0 auto;
}

.WizardActionsFixed {
  position: absolute;
}

.WizardActions {
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  @include respond-to(viewport-7) {
    padding-left: 0;
    padding-right: 0;
  }
}

.WizardActionsInner {
  width: 100%;
  max-width: 518px;
}

.Header {
  margin-bottom: 1rem;
  @include respond-to(viewport-7) {
    margin-bottom: 4rem;
  }
}
