.date {
  width: 43%;
}

.date-separator {
  width: 16px;
  height: 1px;
  flex-shrink: 0;
  background: #d9d9d9;
}
