@import '@kwara/components/src/foundations/_variables.scss';

.Dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  background: brand-col('grey-300');
}

.DotSuccess {
  background: brand-col('green-600');
}

.DotFailure {
  background: brand-col('red-600');
}
