@import '@kwara/components/src/foundations/_variables.scss';

.wrapper {
  position: relative;
  cursor: pointer;
}

.icon {
  --size: 1rem;
  width: var(--size);
  height: var(--size);
  font-size: 0.55rem;
  color: black;
  box-sizing: border-box;
  border-width: 1px;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.info-panel {
  visibility: hidden;
  position: absolute;
  background-color: brand-col(white);
  top: -85%;
  left: calc(100% + 10px);
  color: black;
  box-shadow: 0 0 0 1px brand-col(light-grey-300);
}

.info-panel:after {
  content: '';
  position: absolute;
  top: 20px;
  right: 100%;
}

.wrapper:hover .info-panel {
  visibility: visible;
}
