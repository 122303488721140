@import '@kwara/components/src/foundations/_mixins.scss';

.logo {
  display: inline;
}

.regular,
.small {
  height: 15px;
  margin-left: 5px;
}

.small3x {
  height: 10px;
  margin-left: 2px;
}
