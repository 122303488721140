@import '@kwara/components/src/foundations/_mixins.scss';

.Header {
  position: fixed;
  top: 48px;
  left: 48px;
  right: 48px;
}

.Scrollable {
  padding-top: 48px;
  width: 100%;
  overflow-x: scroll;
}

.Actions {
  margin-top: 24px;
  padding-bottom: 48px;
}
