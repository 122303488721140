.Container {
  width: 100%;
}

.Input {
  display: inline-block;
  width: 30%;
  padding-left: 10px;
}

.InputYear {
  width: 40%;
}

.Input:nth-of-type(1) {
  padding-left: 0;
}

.Input input::-webkit-outer-spin-button,
.Input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Input input[type=number] {
  -moz-appearance: textfield;
}
