@import '@kwara/components/src/foundations/_variables.scss';

.wizard-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px brand-col('neutral-300');
  width: 95%;
  padding: 1rem 0;
  margin: 0 auto;
  height: 81px;

  & h1 {
    width: calc(100% - 24px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & span {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 1.5rem;
  }
}
