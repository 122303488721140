@import '@kwara/components/src/foundations/_variables.scss';

.Wrapper {
  position: relative;
  cursor: pointer;
}

.Icon {
  --size: 1rem;
  width: var(--size);
  height: var(--size);
  font-size: 0.55rem;
  color: black;
  box-sizing: border-box;
  border-width: 1px;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.InfoPanel {
  visibility: hidden;
  position: fixed;
  width: max-content;
  background-color: brand-col(white);
  color: black;
}

.Wrapper:hover .InfoPanel {
  visibility: visible;
}
