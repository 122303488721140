@import '@kwara/components/src/foundations/_variables.scss';

.wizard-container {
  position: fixed;
  width: 95vw;
  max-width: 1233px;
  height: 100%;
  max-height: 90vh;
  top: 2rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: brand-col(white);
  box-shadow: 0px 10px 20px rgba(32, 37, 38, 0.1), 0px 20px 50px rgba(32, 37, 38, 0.1);
  border-radius: 10px;
  overflow: hidden;
}
