@import '@kwara/components/src/foundations/_variables.scss';
@import '@kwara/components/src/foundations/_mixins.scss';

.Product {
  width: 240px;
}

.Product .Header {
  min-height: 60px;
}

.Container {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-auto-flow: column;
  overflow-x: scroll;
  @include respond-to(viewport-9) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: unset;
  }
}
