@import '@kwara/components/src/foundations/_variables.scss';

.EventItem .Indicator {
  align-self: stretch;
}

.EventItem .Indicator:before {
  content: ' ';
  display: block;
  width: 8px;
  height: 8px;

  // light-grey-500 without opacity
  background-color: #d9d9d9;
  border: 8px solid #fff;
  border-radius: 100%;

  margin-right: 16px;
}

.EventItemWrapper:not(:last-child) .Indicator:after {
  content: ' ';
  display: block;
  width: 4px;

  flex: 1 0 auto;
  align-self: stretch;

  background-color: brand-col(light-grey-300);

  transform: translateX(10px);
}

.EventItem .Timestamp {
  width: 192px;
  margin-top: 5px;
}

.EventItem .Info {
  padding-bottom: 40px;
}
