.tabs li {
  display: inline-block;
  margin-left: 2rem;
  color: grey;
}

.tabs li:first-of-type {
  margin-left: 0;
}

.tabs .active {
  border-bottom: 1px solid black;
  color: black;
}
