@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background-color: transparent;
  border: 0;
  color: brand-col('primary-500');
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-decoration: underline;

  &:hover,
  &:active {
    text-decoration: none;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
  }
}
