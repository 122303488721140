.national-id-cell{
/**
* Table Cells have `padding 0.6rem` So, we reset it to `0`
* for NationalID Cell so as to avoid clicking, inaccessibility.
* In other to make the NationalID Cell correlate with Other Cells UI,
* we then move the padding to the `Div Click Handler` that way the
* clicking is accessible even in the padded areas
*/
padding: 0;

& > div{
    height: 100%;
    padding: 0.6rem;
}
}


