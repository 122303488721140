.base {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}

.underline:hover,
.underline:active {
  text-decoration: underline;
}

.noUnderline:hover,
.noUnderline:active {
  text-decoration: none;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.3;
}
