@import '@kwara/components/src/foundations/_variables.scss';
@import '@kwara/components/src/foundations/_mixins.scss';

.input {
  width: 100% !important;
  border-radius: 12px !important;
  height: 48px !important;
  font-family: 'Brown';
  font-size: 16px !important;
  border-color: brand-col(neutral-300) !important;
  background-color: #fff !important;

  &:focus {
    border-color: mobile-brand-col(mobile-primary-200) !important;
  }
}

.button {
  border: none !important;
  border-radius: 12px 0 0 12px !important;
  border: transparent !important;
  border-right: 0 !important;
  background-color: mobile-brand-col(mobile-primary-50) !important;
  height: 95%;
  margin: auto;
  margin-left: 1px;

  & > * {
    background-color: transparent !important;

    &:hover,
    &:focus {
      background-color: transparent !important;
    }
  }
}

.dropdown {
  font-family: 'Brown';
  background-color: brand-col(white) !important;

  &:hover,
  &:focus {
    background-color: brand-col(white) !important;
  }
}

.errorContainer {
  border: solid 1px brand-col('red-500');
  border-radius: 12px;
}
