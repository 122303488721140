@import '@kwara/components/src/foundations/_variables.scss';

$spacing: 20px;

/*
  Fixes the table based on the widths of the first row
  See: https://css-tricks.com/fixing-tables-long-strings/
*/
.Table {
  table-layout: fixed;
  /*   height: 100vh; */

  @media not print {
    border-collapse: collapse;
    border-spacing: 0;
  }
}

.Row {
  vertical-align: baseline;
}

.Cell,
.Heading {
  padding: 0.6rem;
}

.Cell {
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.hasTooltip.Cell:hover {
  overflow: visible;
}

.Cell.hasIcon,
.Heading.hasIcon {
  width: 32px + $spacing;
  padding-left: 0;
  text-overflow: initial;
  overflow: visible;
}

.isClickable {
  cursor: pointer;
}

.isClickable:hover {
  background-color: brand-col(semi-white-400);
}

.Actionable {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.Sticky {
  position: sticky;
  top: 0;
  background-color: white;
  padding-bottom: 1rem;
  margin-top: 0rem;
}
