@use '@kwara/components/src/foundations/_components.scss';
@use '@kwara/components/src/foundations/_variables.scss';
@import '@kwara/components/src/foundations/_mixins.scss';

.width--responsive {
  min-width: components.$card-width;
  width: auto;
}

.width--standard {
  width: components.$card-width;
}

.card {
  height: 104px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  border-radius: 12px;
  border: solid 1px variables.brand-col(neutral-300);
  background-color: white;
}

.title {
  display: flex;
  align-items: center;
}

.info {
  cursor: pointer;
  padding-left: 0.4rem;
}

.font--responsive {
  font-size: 18px;
  @include respond-to(viewport-14) {
    font-size: 25px;
  }
}
