@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background: brand-col(white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--appbase-header-height);
  padding: 0.9375rem 1.5rem;
  transition: all 0.4s ease-in-out;
  width: calc(100% - var(--appbase-leftnavigation-width));
  position: fixed;
  right: 0;
}

.container__column1 {
  display: flex;
  align-items: center;

  & a {
    text-decoration: none;
  }
}

.container__column2 {
  display: flex;
  align-items: center;
}

.notification-wrapper {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.email-accordion {
  position: relative;
}

.email-accordion__header {
  background-color: transparent;
  border: 1px solid brand-col(neutral-400);
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 0.625rem;
  height: 2.125rem;
  min-width: 15.5rem;
  margin-left: 0.625rem;
}

.email-accordion__body {
  background-color: brand-col(white);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 1px 7px 0 rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
  position: absolute;
  top: 1.25rem;
  left: 0;
  right: 0;
  margin: 0.9375rem auto;
  margin-left: 0.625rem;
  overflow: hidden;
  text-align: start;

  & ul {
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;

    & li {
      cursor: pointer;

      &:nth-child(1) {
        padding: 0.75rem 1rem;
        color: brand-col(friarGrey);
      }

      & a {
        color: brand-col(grey-500);
        text-decoration: none;
        display: inline-block;
        padding: 0.75rem 1rem;
        height: 100%;
        width: 100%;
      }

      & button {
        background-color: transparent;
        border: 0;
        border: none;
        cursor: pointer;
        padding: 0.75rem 1rem;
        margin: 0;
        text-align: start;
        width: 100%;
      }

      &:hover {
        background-color: brand-col(semi-white-400);
      }
    }
  }
}
