@import '@kwara/components/src/foundations/_mixins.scss';
@import '@kwara/components/src/foundations/_variables.scss';

.container {
  display: grid;
  grid-template-columns: 100%;
  height: 100vh;

  @include respond-to(viewport-7) {
    grid-template-columns: 40% 60%;
  }
}

.col-one {
  justify-content: center;
  padding: 0 3rem;

  @include respond-to(viewport-12) {
    justify-content: unset;
  }
}

.form {
  width: 100%;
  max-width: 312px;
  @include respond-to(viewport-12) {
    margin-left: 120px;
  }
}

.logo {
  display: block;
  width: 94.643px;
  height: 24px;
  margin-bottom: 4rem;
}

.col-two {
  display: none;

  @include respond-to(viewport-7) {
    height: 100%;
    background-color: brand-col(primary-50);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.col-two__row-one {
  display: flex;
  justify-content: space-between;
  height: 85%;
  width: 85%;
}

.inner-content {
  width: 60%;
  height: 100%;
  background-color: brand-col(white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inner-content__row-one {
  color: brand-col(primary-700);
  line-height: 105%;
  letter-spacing: -0.96px;
  padding: 2rem 3rem;
  font-weight: 500;

  @include respond-to(viewport-7) {
    font-size: 4rem;
  }

  @include respond-to(viewport-3) {
    font-size: 2rem;
  }
}

.inner-content__row-two {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-left: 3rem;
  height: 40%;

  & > img {
    width: 78px;
    height: 20px;
    padding-bottom: 2rem;
  }

  & > div {
    width: 100px;
    height: 100%;
    background: linear-gradient(45deg, transparent 49%, brand-col(primary-300) 49% 51%, transparent 51%),
      linear-gradient(-45deg, transparent 49%, brand-col(primary-300) 49% 51%, transparent 51%);
    background-size: 3em 3em;
    background-color: brand-col(primary-500);
    opacity: 1;
    margin-right: -1px;
  }
}

.bg-pattern {
  width: 40%;
  height: 100%;
  background: linear-gradient(45deg, transparent 49%, brand-col(primary-800) 49% 51%, transparent 51%),
    linear-gradient(-45deg, transparent 49%, brand-col(primary-800) 49% 51%, transparent 51%);
  background-size: 3em 3em;
  background-color: brand-col(primary-700);
  opacity: 1;
}
