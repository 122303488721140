@import '@kwara/components/src/foundations/_mixins.scss';

.info {
  padding-top: 8px;
}

.required {
  margin-left: 3px;
}

.required:after {
  content: '*';
}

.container {
  padding-bottom: 1rem;
  @include respond-to(viewport-7) {
    padding-bottom: 2rem;
  }
}
