@import '@kwara/components/src/foundations/_variables.scss';

/**
 *@sharedStyles
 */
.tab-list {
  background: brand-col(white);
  border: 0.0625rem solid brand-col(neutral-300);
  border-radius: 0.625rem;
  padding: 0.1875rem 0.25rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: fit-content;
}

.tab-item {
  color: brand-col(neutral-600);
  cursor: pointer;
  list-style: none;
  height: 2.375rem;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-out;

  &:not(:last-child) {
    border-right: 0.0625rem solid brand-col(neutral-300);
    margin-right: 0.25rem;
  }

  /**
   *Style specifically for <LinkTab/> 
   */
  & > a {
    color: brand-col(neutral-600);
    height: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
  }
}

.tab-item--active {
  color: brand-col(white);
  background: brand-col(primary-500);
  box-shadow: 0rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  border: 0;
  border: none;

  &:hover {
    background: brand-col(primary-400);
  }

  /**
   *Style specifically for <LinkTab/> 
   */
  & > a {
    color: brand-col(white);
    height: 100%;
  }
}
