@import '@kwara/components/src/foundations/_variables.scss';

/***********************
 * @Lister.tsx styles
 ***********************/
.container {
  background: brand-col('neutral-200');
  border-radius: 12px;
  width: 100%;
}

.lister {
  background: brand-col('neutral-200');
  width: 100%;
  border-collapse: collapse;
}

/***********************
 * @Title.tsx styles
 ***********************/
.title {
  margin: 0;
  padding: 1rem 1rem 0 1rem;
}

/***********************
 * @Head.tsx styles
 ***********************/
.lister-header {
  width: 100%;

  & tr {
    & > * {
      padding: 20px 30px 0 30px;
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      color: brand-col('neutral-500');
    }
  }
}

/***********************
 * @Footer.tsx styles
 ***********************/
.lister-footer {
  background: rgba(19, 19, 19, 0.04);
  width: 100%;
  height: 52px;

  & tr {
    & > * {
      padding: 14px;
      text-align: left;
      font-weight: 700;
      font-size: 16px;
      color: brand-col('neutral-900');
    }
  }
}

/***********************
 * @Trow.tsx styles
 ***********************/
.lister-trow {
  border-bottom: 0.5px solid brand-col('neutral-400');

  & > * {
    padding: 10px 30px;
    font-weight: 400;
    font-size: 14px;
    color: brand-col('neutral-900');
  }
}
