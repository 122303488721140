/* [DEPRECATED] brand colours */
$cols-old: (
  indigo-600: rgb(57, 73, 171),
  indigo-500: rgb(69, 103, 186),
  indigo-100: rgb(216, 220, 235),
  indigo-50: rgb(240, 242, 247),
  green-600: rgb(115, 232, 90),
  red-700: rgb(92, 44, 38),
  red-600: rgb(232, 109, 90),
  red-500: rgb(237, 135, 121),
  red-100: rgb(242, 209, 206),
  red-50: rgb(250, 240, 240),
  teal-700: rgb(42, 69, 69),
  teal-600: rgb(54, 90, 90),
  teal-500: rgb(114, 149, 150),
  teal-200: rgb(168, 200, 201),
  teal-100: rgb(206, 224, 224),
  teal-50: rgb(237, 245, 245),
  // @TODO temporary until we have the design system finalized light-grey-500: rgba(0, 0, 0, 0.15),
  table-head: rgba(247, 250, 252),
  grey-500: rgba(0, 0, 0, 0.84),
  grey-400: rgba(0, 0, 0, 0.54),
  grey-300: rgba(0, 0, 0, 0.34),
  light-grey-500: rgba(0, 0, 0, 0.15),
  light-grey-400: rgba(0, 0, 0, 0.07),
  light-grey-300: rgba(0, 0, 0, 0.04),
  light-grey-200: rgba(0, 0, 0, 0.02),
  semi-white-500: rgb(245, 245, 245),
  semi-white-400: rgb(250, 250, 250),
  modal-background: rgba(250, 250, 250, 0.9),
  modal-background-connect: rgba(244, 244, 244, 1)
);

// [DEPRECATED] Product Design System Colours
// https://www.figma.com/file/365J7L8Yi1i5EyV52e0Fer/Product-Design-System?node-id=260%3A462
$cols-new: (
  info-700: #61411a,
  info-500: #fbbd50,
  info-100: #ffeccf,
  success-500: #2a4545,
  primary-600: #2d5bae,
  grey-200: rgba(0, 0, 0, 0.07),
  grey-500-design-system: rgba(0, 0, 0, 0.54),
  error-600: rgba(232, 109, 90, 1)
);

// Kwara Berry Design System Colours
// https://www.figma.com/file/puEpMKpuSwiVaMyBQuav45/%F0%9F%8E%96-Kwara-Berry-Design-System?node-id=1516%3A20675
$web-cols: (
  neutral-100: #f7fafc,
  neutral-200: #f5f8fc,
  neutral-300: #cbd5e1,
  neutral-400: #94a3b8,
  neutral-500: #64748b,
  neutral-600: #475569,
  neutral-700: #334155,
  neutral-800: #1e293b,
  neutral-900: #131313,
  primary-50: #edfcf2,
  primary-200: #aaf0c4,
  primary-300: #1bb567,
  primary-400: #3ccb7f,
  primary-500: #16b364,
  primary-600: #099250,
  primary-700: #003d37,
  primary-800: #0d4741,
  success-50: rgba(54, 90, 90, 0.04),
  success-200: #a8c8c9,
  success-400: #729596,
  success-500: #365a5a,
  success-600: #085d4d,
  info-50: rgba(251, 189, 80, 0.04),
  info-200: #feeac8,
  info-400: #fccc78,
  info-500: #fbbd50,
  info-600: #faa819,
  error-50: rgba(237, 135, 121, 0.04),
  error-200: #f2d1ce,
  error-400: #f7c7c1,
  error-500: #ed8779,
  error-600: #e65b48,
  white: #fff,
  black: #000,
  mercury: #e3e6ea,
  vanilla-ice: #f4dbd8,
  lavender-mist: rgb(234, 234, 234),
  friar-grey: #7e7e7e,
  box-shadow: rgba(0, 0, 0, 0.09),
  opal: #a9c7c8,
  dust-storm: #e5cdcb,
  tufts-blue: rgba(58, 104, 192, 0.87),
  blue-chalk: #dfedff
);

$mobile-cols: (
  mobile-neutral-100: #fbfbfb,
  mobile-neutral-200: #f1f5f9,
  mobile-neutral-300: #cbd5e1,
  mobile-neutral-400: #94a3b8,
  mobile-neutral-500: #64748b,
  mobile-neutral-600: #475569,
  mobile-neutral-700: #334155,
  mobile-neutral-800: #1e293b,
  mobile-neutral-900: #131313,
  mobile-primary-50: #edfcf2,
  mobile-primary-200: #aaf0c4,
  mobile-primary-400: #3ccb7f,
  mobile-primary-500: #16b364,
  mobile-primary-600: #099250,
  mobile-success-50: #cee0e0,
  mobile-success-200: #a8c8c9,
  mobile-success-300: #b0e5b8,
  mobile-success-400: #729596,
  mobile-success-500: #365a5a,
  mobile-success-600: #085d4d,
  mobile-info-50: #fff9f0,
  mobile-info-200: #feeac8,
  mobile-info-400: #fccc78,
  mobile-info-500: #fbbd50,
  mobile-info-600: #faa819,
  mobile-error-50: #faf0f0,
  mobile-error-200: #f2d1ce,
  mobile-error-400: #f7c7c1,
  mobile-error-500: #ed8779,
  mobile-error-600: #e65b48,
  mobile-dark-aqua: #0a6375,
  mobile-white: #fff,
  mobile-black: #000
);

$cols: map-merge($cols-old, $cols-new);
$design-system-cols: map-merge($cols, $web-cols);
@function brand-col($name) {
  @return map-get($design-system-cols, $name);
}

@function mobile-brand-col($name) {
  @return map-get($mobile-cols, $name);
}
