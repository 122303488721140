.loading {
  width: 300px;
  height: 300px;
  z-index: 1;
  background: transparent;
  opacity: 0.7;
  transition: all 0.3s;
  animation: glowing 5000ms 7;
}

@keyframes glowing {
  0% {
    opacity: 0.7;
  }

  25% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.7;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.7;
  }
}
