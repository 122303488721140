.overlay {
  background-color: rgba(65, 65, 65, 0.7);
}

.items {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
