@use '@kwara/components/src/foundations/_variables.scss';

/****************************
 * styles for PdfViewer.tsx
****************************/
.pdf-viewer {
  width: 90%;
}

.pdf-viewer__btn-wrappers {
  display: flex;
  justify-content: space-between;
  border: solid 2px variables.brand-col(neutral-300);
  border-radius: 5px;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;

  p {
    margin: 0 !important;
    padding: 5px;
  }
}

.button {
  background-color: transparent;
  border: 0;
  margin: 0;
  cursor: pointer;
  padding: 5px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button--next {
  border-left: solid 1px variables.brand-col(neutral-300);
}

.button--prev {
  border-right: solid 1px variables.brand-col(neutral-300);
}

