@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background-color: brand-col(neutral-200);
  margin-top: var(--appbase-header-height);
  margin-left: var(--appbase-leftnavigation-width);
  width: calc(100vw - var(--appbase-leftnavigation-width));
  min-width: var(--appbase-body-min-width);
  min-height: calc(100vh - var(--appbase-header-height) - 30px);
  padding: 0.9375rem 1.5rem;
  transition: all 0.4s ease-in-out;
}
