@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background-color: transparent;
  border: 0;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 0.4rem;
  width: 40px;
  height: 40px;
  text-align: center;
  transition: all 0.5s ease-out;

  &:hover {
    background-color: #eee;
    border-radius: 50%;
  }
}

.dot {
  position: absolute;
  top: -2px;
  right: 0;
  background-color: brand-col(primary-500);
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
