@import '@kwara/components/src/foundations/_variables.scss';

$border: 1px solid brand-col(neutral-300);

.background {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.07);
  background-color: white;
  border-radius: 12px;
  padding: 10px 15px;
  margin: 10px 0;
  border-left: $border;
  border-bottom: $border;
}

.section__grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 15px;
}

.section__grid--template {
  grid-template-columns: repeat(auto-fit, minmax(min-content, 265px));
}

.grid__item {
  display: flex;
  flex-direction: column;
  border-right: $border;
}

.grid__item:last-child {
  border-right: none;
}
