@import '@kwara/components/src/foundations/_variables.scss';

.row1 {
  display: flex;
  justify-content: space-between;

  & .amountNodeLabel {
    color: #718096;
    padding: 0;
    margin: 0;
    display: block;
    padding-bottom: 0.5rem;
  }

  & .amountNodeCurrency {
    color: #1e293b;
    font-weight: 700;
    font-size: 25px;
  }

  & > div:nth-child(2) {
    border-left: 1px solid brand-col('neutral-300');
    padding: 0 5rem;
  }
}

.row2 {
  margin-top: 1rem;
}
