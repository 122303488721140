@import '@kwara/components/src/foundations/_variables.scss';

.container {
  position: relative;
}

.menu-container {
  width: max-content;
  position: absolute;
  top: 0;
  right: 0;
}

.menu {
  background-color: white;
  box-shadow: 0 0 0 1px brand-col(light-grey-300), 0 1px 7px 0 brand-col(light-grey-300);
  border-radius: 8px;
  margin: 5px 0px;
  padding: 8px 1px;
  overflow: hidden;
}

.menu .menu-item {
  cursor: pointer;
  padding: 0;
}

.menu .menu-item,
.menu .menu-item > * {
  color: brand-col(grey-500);
  text-decoration: none;
}

.menu-item:hover,
.menu-item:global(.rc-menu-item-selected) {
  background-color: brand-col(semi-white-500);
}

.menu-item > * {
  display: block;
  padding: 10px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-item[aria-disabled='true'] {
  opacity: 0.6;
  cursor: not-allowed;
}
