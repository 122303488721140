@import '@kwara/components/src/foundations/_variables.scss';

.LeftNavigationContainer {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0;
  padding: 1rem 0.8rem 2rem 0;
  width: 100%;
}

.List {
  margin: 0;
  padding: 0;
}

.Item {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ItemSection {
  margin-top: 0.3rem;
}

.SectionColumn1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.SectionColumn2 {
  display: flex;
  padding-left: 0.7rem;
}

.Title {
  padding: 0;
  margin: 0;
  padding-left: 0.5rem;
}

.TitleActive {
  color: brand-col('primary-600');
}

.TitleInActive {
  color: brand-col('primary-500');
}

.SubTitle {
  padding: 0;
  margin: 0;
  padding-left: 1.1rem;
}

.VerticalConnector {
  width: 2px;
  height: 100%;
  min-height: 50px;
  border-radius: 2px;
}

.Past {
  background-color: brand-col('primary-500');
}

.NotPast {
  background-color: brand-col('neutral-300');
}

.SubTitleActive {
  color: brand-col('neutral-500');
}

.SubTitleInActive {
  color: brand-col('neutral-400');
}

.Link {
  opacity: 1 !important;
}
