.Wrapper {
  position: relative;
}

.OverlayWrapper {
  /* Without this the calendar will inherit
  line-height from the body and appear stretched */
  line-height: initial;
  background-color: white;
  position: absolute;
  left: -20px;
}

.Outside {
  pointer-events: none;
}
