.expander {
  overflow: hidden;
}

.row {
  display: flex;
  justify-content: stretch;
}

.row-content {
  flex: 1;
  margin-right: 24px;
}

.row-content:last-child {
  margin-right: 0;
}
