@import '@kwara/components/src/foundations/_variables.scss';

.section {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.07);
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  margin-top: 30px;
}

.button_bar {
  position: absolute;
  top: -65px;
  right: 10px;
}