@import '@kwara/components/src/foundations/_variables.scss';

.padded {
  margin: 32px 24px 24px 24px;
}

.title {
  min-height: 68px;
}

.DeleteButton {
  background-color: white;
  color: brand-col('red-500');
}

.DeleteButton:hover {
  background-color: brand-col('red-500');
  color: white;
}
