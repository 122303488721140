@import '@kwara/components/src/foundations/_variables.scss';

.SubStep {
  width: 100%;
  height: 100%;
}

.SubStep.actionsHidden {
  margin-bottom: 0;
}

.BodyContainer {
  display: flex;
  height: calc(100% - 97px - 81px); //97px is the height of the WizardHeader, 81px is the height of the WizardFooter
  width: 97%;
  padding: 0 1.2rem;
  margin: 0 auto;
}

.Column1 {
  width: 45%;
  max-width: 300px;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
}

.VerticalDivider {
  width: 2px;
  height: 90%;
  background-color: brand-col('neutral-300');
  border-radius: 2px;
  align-self: center;
}

.Column2 {
  width: 55%;
  min-width: calc(100% - 300px);
  padding-left: 1rem;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 2rem;
}
