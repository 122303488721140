@import '@kwara/components/src/foundations/_variables.scss';

@keyframes sharpness {
  0% {
    opacity: 0.7;
  }

  50% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

/************************
 *@<nav/> styles
 ************************/
.container {
  position: fixed;
  background-color: brand-col(white);
  height: 100vh;
  padding: 0.9375rem 1.5rem;
  padding-bottom: 2rem;
  overflow: hidden;
  overflow-y: scroll;
  transition: all 0.4s ease-in-out;
  width: var(--appbase-leftnavigation-width);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & a {
    text-decoration: none;
  }

  & button {
    cursor: pointer;
  }
}

/************************
 *@logo-row-li styles
 ************************/
.logo-li {
  display: flex;
  align-items: center;

  & button {
    background-color: transparent;
    border: 0;
    padding: 0;
    padding-right: 25%;
  }
}

.add-new-li {
  background: brand-col(primary-600);
  border-radius: 0.27216875rem;
  color: brand-col(white);
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  padding: 0.4rem;
  height: 3.4375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background: brand-col(primary-500);
  }
}

.nav-item {
  border-radius: 0.27216875rem;
  padding: 0.4rem;
  display: flex;
  margin: 0.8rem auto;
  height: 4.125rem;
  width: 90%;
  transition: all 0.4s ease-in-out;
  align-items: center;

  /**
   *centers the span of svg icon and the span of text
   */
  & > span {
    display: flex;
    align-items: center;
    height: 100%;
  }

  & * {
    color: brand-col(neutral-800);
  }

  &:hover {
    background: brand-col(mercury);
  }
}

.nav-item--active {
  background: brand-col(mercury);
}

.nav-item--closed {
  flex-direction: column;
  justify-content: center;
}

.nav-item--opened {
  & > span {
    &:nth-child(2) {
      margin-left: 0.4rem;
    }
  }
}

.list,
.withsubnav-list {
  animation: sharpness 0.5s ease-in-out;
}
