@import '@kwara/components/src/foundations/_variables.scss';

.wrapper {
  position: relative;
}

.menu {
  // @TODO: Can this be a brand colour?
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.07);
  position: absolute;
  left: 0;
  // @TODO: How best to do width?
  width: 500px;
}

.buttonBar {
  border-top: 1px solid brand-col('grey-200');
  display: flex;
  justify-content: space-between;
}
