@import '@kwara/components/src/foundations/_variables.scss';

@mixin scrollbar() {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

/**
 * Left hand side navigation styles 
 */
.container {
  border-radius: 12px;
  border: 1px solid brand-col(neutral-300);
  background: brand-col(white);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.08);
  position: absolute;
  display: flex;
  height: 400px;
  width: 100vw;
  max-width: 700px;
}

.container--disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.container__nav {
  @include scrollbar();
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background: brand-col(neutral-100);
  padding: 1rem;
  height: 100%;
  width: 30%;

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;

    & li {
      width: 100%;
      height: 100%;
      margin: 10px 0;
    }
  }
}

.item__action {
  color: rgba(30, 41, 59, 0.6);
  margin: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 100%;

  & > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 90%;
    padding: 0 5px;
  }

  &.item__action--active {
    color: brand-col(neutral-800);
    background-color: rgba(58, 104, 192, 0.05);
    border: solid 1px brand-col(primary-500);
  }
}

.name-wrapper {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: left;

  & > span {
    &:nth-child(1) {
      display: inline-block;
      padding-right: 5px;
    }
  }
}

.arrow-wrapper {
  padding: 0;
  margin: 0;
}

.arrow-icon {
  transform: rotate(-90deg);
}

/**
 * Right hand side content styles 
 */
.container__page {
  @include scrollbar();
  padding: 1rem;
  height: 100%;
  width: 70%;
}
