.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  padding: 1rem;
}

.amount {
  height: 48px;
  border-radius: 8px;
  padding: 0 16px;
}
