@import '@kwara/components/src/foundations/_variables.scss';

.Row {
  display: flex;
  justify-content: stretch;
}

.RowContent {
  flex: 1;
  margin-right: 24px;
}

.RowContent:last-child {
  margin-right: 0;
}

.Expander {
  overflow: hidden;
}

.ExpandBar {
  margin-top: 20px;
}

.ExpandBtn {
  background-color: brand-col(white);
  top: -20px;
  position: relative;

  &__open {
    transform: rotate(180deg);
    border-color: brand-col('primary-500');
  }
}

.isExpanded .ExpandBar {
  pointer-events: none;
}
