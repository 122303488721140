@import '@kwara/components/src/foundations/_mixins.scss';
@import '@kwara/components/src/foundations/_variables.scss';

.field {
  display: flex;
  gap: 16px;
}

.square {
  height: 20px;
  width: 20px;
  background-color: brand-col(primary-500);
  border-radius: 6px;
}

.feeList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 25px;
}

.feeInputs {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  align-items: center;
  column-gap: 25px;
}

.FeeAmount {
  height: 18px;
  width: auto;
  border: 1px solid brand-col(neutral-500);
  border-radius: 3px;
  margin-left: 8px;
  text-align: center;
}

.icon {
  cursor: pointer;
}
