@keyframes skeleton-loading {
  0% {
    color: inherit;
    background-color: inherit;
  }

  100% {
    color: hsl(200, 20%, 95%);
    background-color: hsl(210, 6%, 87%);
  }
}

.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
  margin-bottom: 0.25rem;
  font-size: inherit;
}
