@import '@kwara/components/src/foundations/_variables.scss';
@import '@kwara/components/src/foundations/_mixins.scss';

$textPastCol: brand-col(grey-400);
$textFutureCol: brand-col(grey-400);
$textCurrentCol: #000;

$pastCol: brand-col(grey-500);
$currentCol: brand-col(grey-500);
$futureCol: brand-col(light-grey-400);

.Container {
  display: none;
  @include respond-to(viewport-9) {
    display: inherit;
    list-style: none;
    margin: 0;
    flex-shrink: 0;
  }
}

.Item {
  border-color: $futureCol;
}

.Item.current {
  border-bottom-color: $currentCol;
}

.Item:last-child {
  border-right: none;
}

.past .Text {
  color: $textPastCol;
}

.current .Text {
  color: $textCurrentCol;
}

.future .Text {
  color: $textFutureCol;
}

.StepCounter {
  position: relative;
  width: 28px;
  height: 28px;

  margin-right: 8px;
}

.StepCounter > :not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Number {
  width: 28px;
  height: 28px;

  border-radius: 50%;
}

.past .Number {
  color: #fff;
  background-color: brand-col(teal-500);
}

.current .Number {
  border-color: #000;
}

.future .Number {
  color: $textFutureCol;
}

.current .ProgressTrack {
  fill: brand-col(light-grey-500);
}

.ProgressBar {
  fill: brand-col(grey-500);

  transition: d 0.3s ease-in-out;
}

.future .ProgressTrack,
.future .ProgressBar {
  fill: $futureCol;
}

.Link {
  &__hidden {
    visibility: hidden;
  }
}

.Dot {
  font-size: 7px;
  opacity: 0.3;
  margin: 0.5rem;
  text-decoration: none !important;
}
