:root {
  --appbase-leftnavigation-width: 150px;
  --appbase-closed-leftnavigation-width: 150px;
  --appbase-opened-leftnavigation-width: 200px;
  --appbase-header-height: 60px;
  --appbase-body-min-width: 1200px;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.app-wrapper {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
  height: 100%;
}
