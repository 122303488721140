@import '@kwara/components/src/foundations/_variables.scss';

.container {
  color: brand-col(neutral-400);
  list-style: none;
  margin-top: 6.875rem;
  padding: 0 2rem;

  & button {
    cursor: pointer;
  }

  .container__row1 {
    color: brand-col(neutral-400);
    display: inline-block;
    margin: 1rem 0;
  }

  .container__row2 {
    border: 0.09375rem solid brand-col(neutral-400);
    border-radius: 0.75rem;
    padding: 0.625rem;

    & p {
      line-height: 150%;
    }
  }

  .container__row2--active {
    border-color: brand-col(primary-500);
  }

  .heading-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & a {
      color: brand-col(primary-400);
      text-decoration: none;
    }

    & button {
      background-color: transparent;
      border: none;
      border: 0;
    }
  }
}

.check-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & i {
    padding-right: 0.5rem;
  }
}

.check {
  background-color: transparent;
  border: 0.0625rem solid brand-col(neutral-400);
  border-radius: 0.125rem;
}

.check--read {
  cursor: not-allowed !important;
}

.points {
  & li {
    list-style: circle;
  }
}
