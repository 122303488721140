@use '@kwara/components/src/foundations/_variables.scss';

.container {
  background: variables.brand-col(white);
  border: 1px solid variables.brand-col(neutral-300);
  box-shadow: 0rem 0rem 1.5rem 0.3125rem variables.brand-col(box-shadow);
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0.625rem;
  right: 1rem;
  width: 90%;
  max-width: 31.25rem;
  height: 7.5rem;
}

.container__row1 {
  height: 100%;
  width: 30%;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;

  & img {
    width: 100px;
  }
}

.container__row1--back-online {
  background-color: variables.brand-col(opal);
}

.container__row1--gone-offline {
  background-color: variables.brand-col(dust-storm);
}

.container__row2 {
  padding: 1.25rem;
  width: 70%;
}
