@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background-color: brand-col(white);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 6.25rem;
  padding: 1rem 2rem;
  width: 90%;
  max-width: 39.25rem;
  margin: 0 auto;

  & > button {
    background-color: transparent;
    border: 0.0625rem solid brand-col(primary-500);
    border-radius: 0.375rem;
    color: brand-col(primary-500);
    cursor: pointer;
    padding: 0.625rem;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
}
