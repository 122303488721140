@import '@kwara/components/src/foundations/_mixins.scss';

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  grid-template-areas: 'big-charts big-charts small-charts';
  gap: 1rem;
  text-align: center;
}

.big-charts {
  grid-area: big-charts;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.small-charts {
  grid-area: small-charts;
  grid-column-end: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chart-container {
  background: white;
  border-radius: 10px;
  padding: 1rem;
}

.legend {
  display: flex;
  column-gap: 20px;
  justify-content: center;
}

.responsive-container {
  width: 100%;
}

.pie-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.h-508 {
  height: 508px;
}

.h-300 {
  height: 300px;
}

.square {
  height: 24px;
  width: 24px;
  border-radius: 5px;
}

.balance-sum {
  position: relative;
  top: 50%;
}
