@import '../foundations/mixins';

.Overview {
  min-height: 350px;
  margin: 0 16px;

  @include respond-to(viewport-12) {
    padding-left: 88px;
    padding-right: 88px;
  }
}

.Content {
  margin: 20px;
  @include respond-to(viewport-12) {
    padding: 0px;
    margin: 0px;
  }
}

.NoBorderRadius {
  border-radius: 0;
}
