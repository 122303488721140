@import '@kwara/components/src/foundations/_variables.scss';

.container {
  margin: 1.5rem 0;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;

  & > button {
    padding: 0 1rem;
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
