.Content {
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
  height: 4rem;
  opacity: 1;
  transition: opacity 0.15s ease-in;

  .MobileContent {
    display: none;
  }
}

.Content:hover {
  opacity: 0.5;
  transition: opacity 0.15s ease-in;
}

.LinkContainer {
  position: relative;
}

/* We make the input type="file" transparent without hiding it form
  the DOM so the user can still click on it. We also make sure it takes
  the full size of its parent so the target is as big as possible
*/
.InputFile {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  z-index: 2;
}

.InputText {
  z-index: 1;
  position: relative;
}
