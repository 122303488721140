@import '@kwara/components/src/foundations/_mixins.scss';

.Section {
  padding: 24px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'a c'
    'b b';
  @include respond-to(viewport-7) {
    grid-template-areas: 'a b c';
    grid-template-columns: 2fr 4fr 1fr;
  }
}

.Content {
  grid-area: b;
  text-align: left;
  @include respond-to(viewport-7) {
    display: flex;
  }
}

.Section:last-child {
  border-bottom: none;
}

.Heading {
  grid-area: a;
  text-align: left;
}

.EditColumn {
  grid-area: c;
  text-align: right;
}

.wrap {
  display: flex;
  flex-direction: column;
  @include respond-to(viewport-7) {
    flex-direction: row;
  }
}
