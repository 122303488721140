.container {
  flex-shrink: 0;
}

.mobile-container-col2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
