.tbody-details-row__col {
  & h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }

  & ul {
    padding: 0;

    & li {
      list-style: none;

      & h3 {
        color: rgba(0, 0, 0, 0.34);
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
      }

      & p {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
      }

      & div {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
      }
    }
  }
}
