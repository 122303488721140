@import '@kwara/components/src/foundations/_variables.scss';

.container {
  border-radius: 1rem;
  position: fixed;
  top: 12%;
  left: calc(var(--appbase-leftnavigation-width) + 10px);
  background: brand-col(white);
  box-shadow: 0rem 0.5rem 2.25rem rgba(0, 0, 0, 0.16);
  padding-bottom: 1rem;
  width: 22.625rem;

  & h2 {
    color: brand-col(black);
    padding: 1rem 1rem 0.5rem 1rem;
  }

  & ul {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;

    & li {
      border-bottom: solid 1px brand-col(neutral-300);

      & a {
        color: #575757;
        display: inline-block;
        text-decoration: none;
        padding: 0.625rem 1rem;
        width: 100%;

        &:hover {
          color: brand-col(black);
        }
      }
    }
  }
}
