@use '@kwara/components/src/foundations/_variables.scss';

.LoanDetails {
  width: 385px;
  height: 258px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 11px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 0 24px 5px variables.brand-col(box-shadow);
  border: solid 1px variables.brand-col(neutral-300);
  background-color: variables.brand-col(neutral-50);
}
