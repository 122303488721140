@import '@kwara/components/src/foundations/_variables.scss';

// These variables should allow this to be easily resized and still
// have proper behaviour, however, they assume the border width will always be 1px,
// so...don't change the border widths.

$width: 67px;
$height: 39px;

$diameter: calc(#{$height} - 4px);
$distance: calc(#{$width} - #{$height});

$transition-time: 0.4s;

.switch {
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: brand-col(white);
  -webkit-transition: $transition-time;
  transition: $transition-time;
  border: 1px solid brand-col('grey-200');
}

.slider:before {
  position: absolute;
  content: '';
  height: $diameter;
  width: $diameter;
  left: 0;
  bottom: 0;
  background-color: brand-col(white);
  -webkit-transition: $transition-time;
  transition: $transition-time;
}

input:checked + .slider {
  background-color: brand-col('primary-600');
}

input:focus + .slider {
  box-shadow: 0 0 1px brand-col('primary-600');
}

input:disabled + .slider {
  cursor: default;
  filter: alpha(opacity=75);
  -moz-opacity: 0.75;
  -khtml-opacity: 0.75;
  opacity: 0.75;
}

input:checked + .slider:before {
  -webkit-transform: translateX($distance);
  -ms-transform: translateX($distance);
  transform: translateX($distance);
}

/* Rounded sliders */
.slider.round {
  border-radius: $height;
}

.slider.round:before {
  border-radius: 50%;
  border: 1px solid brand-col('grey-200');
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
}
