@import '@kwara/components/src/foundations/_mixins.scss';
@import '@kwara/components/src/foundations/_variables.scss';

.MobileProgressContainer {
  flex-wrap: nowrap;
  gap: 1rem;
  justify-content: space-between;
}

.MobileProgressItem {
  flex-grow: 1;
  height: 6px;
  border-radius: 30px;
  background-color: mobile-brand-col('mobile-primary-50');
}

.visited {
  background-color: mobile-brand-col('mobile-primary-500');
}
