.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'a a'
    'b b'
    'c d';
  gap: 1rem;
}

.payment {
  grid-area: a;
}

.date {
  grid-area: b;
}
