@import '@kwara/components/src/foundations/_variables.scss';

.card {
  background: brand-col(white);
  border-radius: 1rem;
  padding: 0;
  position: fixed;
  top: 5rem;
  right: 1.5rem;
  margin: 0 auto;
  height: 31.25rem;
  max-height: 85vh;
  width: 90%;
  max-width: 39.5rem;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  /* WebKit */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.no-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  & img {
    width: 100%;
    max-width: 250px;
  }
}
