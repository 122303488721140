@import '@kwara/components/src/foundations/_variables.scss';

.expand-bar {
  margin-top: 20px;
}

.expand-btn {
  background-color: brand-col(white);
  top: -20px;
  position: relative;

  &--open {
    transform: rotate(180deg);
    border-color: brand-col('primary-500');
  }
}
