@import '@kwara/components/src/foundations/_variables.scss';

.totalRow {
  border-bottom: 1pt solid black;
}

.totalLabel {
  color: brand-col(neutral-800);
  font-weight: bold;
}

.submit {
  position: relative;
}

.submit__error {
  position: absolute;
  top: 100%;
  left: 0;
  white-space: nowrap;
}