@import '@kwara/components/src/foundations/_variables.scss';

.totalsWrapper {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;

  & section {
    width: 48%;

    & * {
      margin: 0;
      padding: 0;
    }

    & p {
      padding: 0.5rem 1rem;
      margin-top: 0.5rem;
      border-radius: 6px;
      background-color: brand-col('neutral-300');
    }
  }
}

.secondaryIncomeContainer {
  border: 0;
  padding: 0;
  margin: 0;

  & label {
    padding: 1rem 0;
  }
}

.secondaryIncomeRow2 {
  display: flex;
  justify-content: space-between;

  & > * {
    width: 48%;
  }
}

.addSecondaryIncomeLinkButton {
  margin-bottom: 2rem !important;
}

.incomeWrapper {
  display: flex;
  justify-content: space-between;

  & > * {
    width: 48%;
  }
}
