.icon-wrapper {
  padding-left: 1rem;
}

.icon-arrow--open {
  transform: rotate(-360deg);
}

.icon-arrow--closed {
  transform: rotate(-180deg);
}

.accordion__heading {
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 100%;
}
