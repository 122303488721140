@import '@kwara/components/src/foundations/_mixins.scss';
@import '@kwara/components/src/foundations/_variables.scss';

.Container {
  display: grid;
  height: 100vh;
  grid-template-columns: 100%;
  @include respond-to(viewport-7) {
    grid-template-columns: 50% 50%;
  }
}

.Image {
  background-image: url('~@kwara/components/src/LogIn/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  min-height: 90px;
}

.Logotype {
  width: 36px;
  height: 36px;
  top: 30px;
  left: 30px;
  position: relative;
}

.Content {
  max-width: 600px;
  display: grid;
  grid-template-rows: 92% 8%;
}
