.Banner {
  text-align: center;
  font-size: 0.75rem;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  height: 1.25rem;
  width: 100vw;
}
