@import '@kwara/components/src/foundations/_variables.scss';

.container {
  display: flex;
  align-items: center;
}

.container--disabled {
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;
}

.filters-list {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin: 0 1rem;
  padding: 0;
  padding-left: 1rem;
  border-left: 1px solid brand-col(neutral-300);
}

.button {
  border: 0;
  color: brand-col('neutral-800');
  cursor: pointer;
  height: 35px;
  padding: 0;

  & > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 10px;
    width: fit-content;

    & span {
      padding: 0 3px;
      margin: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

.button--filter {
  background-color: brand-col(blue-chalk);
}

.button--add {
  background-color: brand-col(mercury);
  width: 140px;
}

.button-reset {
  background-color: transparent;
  color: brand-col('primary-500');

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.no-filters {
  color: brand-col('neutral-600');
  margin: 0 1rem;
  padding: 0;
}
