@import '@kwara/components/src/foundations/_mixins.scss';

.Card {
  height: 647px;
  overflow: scroll;
}

.container {
  width: 100%;
  display: grid;
  grid-template-areas:
    'a c'
    'b d';
  grid-template-columns: 45% 55%;
}

.a {
  grid-area: a;
  text-align: left;
}

.b {
  grid-area: b;
  text-align: left;
}

.c {
  grid-area: c;
  text-align: right;
}

.d {
  grid-area: d;
  text-align: right;
}

.scrollable {
  height: 500px;
  overflow: scroll;
  position: relative;
}
