@use '@kwara/components/src/foundations/_components.scss';

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, components.$card-width);
  align-items: center;
  gap: 15px;
  padding: 0;
}

.item {
  justify-self: stretch;
}
