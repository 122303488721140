@import '@kwara/components/src/foundations/_variables.scss';

.MemberSearch.withResultCount > :first-child {
  margin-bottom: 48px;
}
.ResultList {
  margin-top: 0px;
}

.Result {
  background: #ffffff;
  padding: 5px 20px;

  cursor: pointer;
}

.floatResults .ResultContainer {
  position: absolute;
  top: 80px;
}

/*
  When collapsed, the entire .Results list is rounded
  otherwise each .Result is rounded
*/
.isCollapsed.hasResults .Results,
.isNotCollapsed .Result {
  max-height: 80vh;
  overflow-y: scroll;
  box-shadow: 0 0 0 1px brand-col('light-grey-400'), 0 1px 7px 0 brand-col('light-grey-400');
  border-radius: 8px;

  // The below styles hides scrollbar
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.isCollapsed .Results {
  .Result:first-child {
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
  }

  .Result:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
}

.Results > * {
  margin-bottom: 8px;
}

.isCollapsed .Results > * {
  margin-bottom: 0;
  border-bottom: 1px solid brand-col('light-grey-400');
}

.isCollapsed .Results > * {
  margin-bottom: 0;
  border-bottom: 1px solid brand-col('light-grey-400');
}

.isHighlighted {
  background-color: brand-col('indigo-50');
}

.isDisabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.NoResults {
  width: max-content;
  border-radius: 8px;
  cursor: default;
}

.ResultMember {
  // This must be fixed so the result columns
  // line up in each row
  width: 195px;
}

.NonFinancialResultName {
  // This must be fixed so the result columns
  // line up in each row
  width: 250px;
}

.NonFinancialResultNumber {
  // This must be fixed so the result columns
  // line up in each row
  width: 150px;
}

.NonFinancialResultID {
  // This must be fixed so the result columns
  // line up in each row
  width: 100px;
}

/* utility classes */
.noFlex {
  flex: 0 0;
}

/*Search bar label*/
.Label {
  color: brand-col('neutral-700');
  margin-bottom: 0.5rem;
  display: block;
}
