.EligibilityPanel {
  display: flex;
  flex-wrap: wrap;
  width: 280px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 0 0 1px brand-col(light-grey-300),
    0 1px 7px 0 brand-col(light-grey-300);
}

.EligibilityThumbnail {
  flex: 1 1 120px;
}
