@import '@kwara/components/src/foundations/_variables.scss';

.section {
  margin: 1.5rem 0;

  & h1 {
    color: brand-col('neutral-700');
  }
}

.loansListbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;

  & li {
    list-style: none;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}
