@import '@kwara/components/src/foundations/_variables.scss';
@import '@kwara/components/src/foundations/_mixins.scss';

$spacing: 24px;

.GridContainer {
  margin-left: -$spacing;
  margin-right: -$spacing;
}

.GridRow {
  margin-bottom: 0;
  @include respond-to(viewport-7) {
    margin-bottom: $spacing;
  }
}

.GridCell {
  padding: 0 $spacing;
}

.hasBorder .GridCell {
  border-right: 1px solid brand-col(light-grey-400);
}

.GridRow > :last-child {
  border-right: none;
}

.GridContainer > :last-child {
  margin-bottom: 0;
}
