.Panel {
  max-width: 500px;
}

.messages-wrapper {
  height: 100%;
  max-height: 500px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  & > li {
    margin: 0.5rem 0;
  }
}
