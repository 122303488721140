@import '@kwara/components/src/foundations/_variables.scss';

.primary:not(.disabled):focus,
.primary:not(.disabled):hover,
.primary:not(.disabled):active {
  background-color: brand-col(primary-500);
}

.tiny {
  height: 18px;
  padding-bottom: 2px;
}

.small {
  height: 32px;
  padding: 0 14px;
}

.regular {
  height: 40px;
  padding: 0 18px;
}

.medium {
  height: 48px;
  padding: 0 22px;
}

.large {
  height: 50px;
  padding: 0 58px;
}

.glyphOnly {
  padding: 0 1px 0 9px;
}

.glyphLeft {
  margin-right: 8px;
}

.glyphRight {
  margin-left: 8px;
}

.regular .glyphLeft,
.regular .glyphRight {
  width: 18px;
  height: auto;
}

.medium .glyphLeft,
.medium .glyphRight {
  width: 24px;
  height: auto;
}

.enabled {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.content {
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  visibility: hidden;
}

.br {
  border-radius: 6px;
}

.mobile-primary {
  background-color: mobile-brand-col(mobile-primary-600);
  color: mobile-brand-col(white);
}

.mobile-primary-disabled {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}

.websacco-primary {
  background-color: brand-col(primary-600);
  color: mobile-brand-col(white);
}

.websacco-primary-disabled {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}
