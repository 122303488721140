@import '@kwara/components/src/foundations/_variables.scss';

.container {
  width: 75px;
  height: 73px;
}

.label {
  display: flex;
  flex-direction: column;
  border: 1px solid brand-col(grey-400);
  color: brand-col(grey-400);
  border-radius: 16px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.radioInput {
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  position: fixed;
  &:checked,
  &:active {
    & ~ label {
      opacity: 1;
      border-color: brand-col(indigo-600);
      color: brand-col(indigo-600);
      path {
        fill: brand-col(indigo-600);
        fill-opacity: 1;
      }
    }
  }
}
