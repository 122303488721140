@use '@kwara/components/src/foundations/_components.scss';

.indicators {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(components.$card-width, 1fr));
  grid-auto-rows: min-content;
  gap: 1rem;
  margin: 0;
}
