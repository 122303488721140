@import 'mixins';
/* Foundation typography sizes and line-heights */

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/password.ttf);
}

@font-face {
  font-family: 'Brown';
  src: url(./fonts/Brown-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: 'Brown';
  src: url(./fonts/Brown-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: 'Brown';
  src: url(./fonts/Brown-Light.otf);
  font-weight: 300;
}

body {
  font-family: 'Brown', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  font-size: 16px;
  font-weight: normal;

  letter-spacing: 0;
  line-height: 22px;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
}

// [DEPRECATED] - use Product Design System Typography
@include kw-text(big, 36px, 44px);
@include kw-text(super-large, 26px, 36px);
@include kw-text(extra-large, 24px, 32px);
@include kw-text(regular, 16px, 22px);
@include kw-text(small, 14px, 18px);
@include kw-text(extra-small, 12px, 18px);

// [DEPRECATED] Product Design System Typography
@include kw-text(5x-large, 64px, 64px);
@include kw-text(4x-large, 48px, 52px);
@include kw-text(3x-large, 36px, 44px);
@include kw-text(2x-large, 26px, 36px);
@include kw-text(x-large, 24px, 32px);
@include kw-text(large, 21px, 30px);
@include kw-text(medium, 18px, 26px);
// Temporary name to disambiguate from deprecated-small
// TODO: change back to 'small'
@include kw-text(small-design-system, 16px, 22px);
@include kw-text(x-small, 14px, 18px);
@include kw-text(2x-small, 12px, 16px);
@include kw-text(3x-small, 10px, 14px);

// Kwara Berry Design System Typography
// https://www.figma.com/file/puEpMKpuSwiVaMyBQuav45/%F0%9F%8E%96-Kwara-Berry-Design-System?node-id=1516%3A20675
@include kw-text(4x-large, 48.8px, 61px);
@include kw-text(3x-large, 39.1px, 48.87px);
@include kw-text(2x-large, 31.2px, 39px);
@include kw-text(x-large, 25px, 31.25px);
@include kw-text(large, 20px, 25px);
@include kw-text(medium, 16px, 24px);
@include kw-text(small, 14px, 21px);
@include kw-text(x-small, 10.2px, 15.3px);

// Connect typography
@include mobile-text(x-large, 24px, 36px);
@include mobile-text(large, 20px, 25px);
@include mobile-text(medium, 16px, 24px);
@include mobile-text(small, 14px, 21px);
@include mobile-text(x-small, 12px, 18px);

.kw-weight-light {
  font-weight: 300;
}

.kw-weight-regular {
  font-weight: normal;
}

.kw-weight-bold {
  font-weight: bold;
}

.kw-numeric {
  font-variant-numeric: slashed-zero;
}
