.container {
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;

  & .not-now-btn {
    cursor: pointer;
    padding: 0.5rem 0;
  }
}
