@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background: #fff;
  border: 1px solid brand-col('neutral-300');
  border-radius: 6px;
  padding: 24px;
  max-width: 534px;
  min-height: 249px;
  width: 100%;
}

.container--danger {
  border: 1px solid brand-col('error-500');
}

.container__row1 {
  background-color: transparent;
  border: 0; //other browsers
  border: none; //webkit
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.container__row2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & label {
    color: brand-col('neutral-600');
    display: inline-block;
    padding-bottom: 5px;
  }

  & span {
    display: block;
    color: brand-col('neutral-800');
  }
}

.container__row3 {
  margin: 1rem 0 !important;

  & div {
    padding-bottom: 0 !important;
  }
}

.container__row4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 6px;
    width: 45%;
    max-width: 182px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s ease-out;

    &:nth-child(1) {
      color: brand-col('error-500');
      border: 1px solid brand-col('error-500');

      &:hover {
        opacity: 0.8;
      }
    }

    &:nth-child(2) {
      background: brand-col('primary-500');
      color: #fff;

      &:hover {
        opacity: 0.8;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
