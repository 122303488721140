@use '@kwara/components/src/foundations/_variables.scss';

.container {
  background: variables.brand-col(white);
  border: 1px solid variables.brand-col(neutral-300);
  box-shadow: 0px 0px 24px 5px variables.brand-col(box-shadow);
  border-radius: 6px;
  padding: 20px;
  min-width: 341px;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
