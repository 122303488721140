.wizard-footer-container {
  background: #f0f1f1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 40px;
  margin: 0;
  height: 97px;
}

.left-buttons-wrapper {
  margin: 0;

  & button {
    margin: 0;
  }
}

.right-buttons-wrapper {
  margin: 0;

  & button {
    margin: 0;
    margin-left: 1rem;
  }
}
